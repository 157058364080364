<template>
    <BaseFilter removeSearchBar title="receivable_filter">
        <div class="item action btn-group">
                <baseButton class="btn-filter btn-primary w-100" data-bs-toggle="dropdown" ariahashpopup="true" aria-expanded="true" data-bs-auto-close="outside">
                    <font-awesome-icon icon="far fa-filter" />
                    Filtros
                    <span class="filters_qty_active">{{ totalFilters }}</span>
                </baseButton>
                <div class="dropdown-menu dropdown-menu-end py-0"  aria-labelledby="dropdownMenu">
                    <FilterReceivables @filters="filters" :initialFilters="initialFilters" @filtersQty="filtersQty"/>
                </div>
            </div>
    </BaseFilter>
    <BaseTable class="no_top_radius" :columns="tableColumns" :paginate="paginate" :isLoading="isLoading" :qty="receivables.length"  @changePage="changePage">
        <tr v-for="(receivable, key) in withdrawalStore.receivables" :key="key">
            <td>{{ receivable.id_order }}</td>
            <td>{{ globalStore.formatMoney(receivable.amount) }}</td>
            <td><BaseBadget :text="'Pendente'" :color="'#DCA817'" /></td>
            <td>{{ globalStore.formatDate(receivable.available_date) }}</td>
            <td class="actions"></td>
        </tr>
    </BaseTable>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import BaseFilter from '@/components/ui/BaseFilter';
import FilterReceivables from '@/components/includes/withdrawal/ReceivablesFilter.vue'
import { useWithdrawalStore } from '@/stores/withdrawal';
const withdrawalStore = useWithdrawalStore();
const { receivables, paginate } = storeToRefs(withdrawalStore);

import { useGlobalStore } from '@/stores/global';
const globalStore = useGlobalStore();

const tableColumns = ['Pedido', 'Valor', 'Status', 'Data'];

const isLoading = ref(false);

import { useRouter, useRoute } from 'vue-router';
const route = useRoute();
const router = useRouter();

let initialFilters = null;
const totalFilters = ref(0);

onBeforeMount(() => {
    if(!globalStore.hasPermissionTo('wallet')) {
        return router.push('/403');
    }

    if(withdrawalStore.anticipations.length == undefined){
        isLoading.value = true;
    }
    
    if(route.query.page || route.query.date_start || route.query.date_end) {

        Object.keys(withdrawalStore.receivables_filters).forEach((key) => {
            let currentThing = route.query[key];
            withdrawalStore.receivables_filters[key] = '';
            withdrawalStore.receivables_filters[key] = currentThing;

            initialFilters = withdrawalStore.receivables_filters;
            let param = route.query[key];
            withdrawalStore.receivables_filters[key] = (param == undefined) ? null : param;
        });
    }

    loadReceivables().then(() => {
        isLoading.value = false;
    });

    if(withdrawalStore.receivables_filters.date_start && withdrawalStore.receivables_filters.date_end){
        initialFilters = {};
        initialFilters['date_range'] = withdrawalStore.receivables_filters.date_start + '@' + withdrawalStore.receivables_filters.date_end
    }
});

function filters(r) {
    Object.keys(r).forEach((key) => {
        withdrawalStore.receivables_filters[key] = r[key];
    });
    globalStore.loader('show');
    loadReceivables();
}

function filtersQty(r) {
    totalFilters.value = r;
}

async function loadReceivables() {
    let usedParams = {};
    Object.keys(withdrawalStore.receivables_filters).forEach((key) => {
        if(withdrawalStore.receivables_filters[key] != null && withdrawalStore.receivables_filters[key] != '') {

            let currentParams = {
                ...usedParams,
                [key]: withdrawalStore.receivables_filters[key]
            };
            usedParams =  currentParams;
        }
    });

    router.replace({query: usedParams});
    await withdrawalStore.tryReceivables(withdrawalStore.receivables_filters);
    globalStore.loader('hide');
}

let isChangingPage = false;
async function changePage(p) {
    if(isChangingPage) return;
    withdrawalStore.receivables_filters.page = p;
    globalStore.loader('show');
    isChangingPage = true;
    loadReceivables().then(() => {
        isChangingPage = false;
        window.scrollTo({ top:0, left:0, behavior: "instant"});
    });
}
</script>